import React, { useState } from 'react'

import Input from '../input'

import styles from './style.module.scss'

import Form from '../form'

import PhoneInput from '../phone-input'

import Button from '../button'

import CalculatorImage from '../../images/calculator.svg'

import RelatedPosts from '../related-posts'

function calculate({
    totalAmount,
    interestRate,
    amortizationPeriod,
    downPayment
}) {
    totalAmount = parseFloat(totalAmount) - parseFloat(downPayment)
    let monthlyInterest = parseFloat(interestRate) / 100 / 12
    var payments = parseFloat(amortizationPeriod) * 12
    var x = Math.pow(1 + monthlyInterest, payments)
    var monthlyPayment = (totalAmount * x * monthlyInterest) / (x - 1)

    if (isFinite(monthlyPayment)) {
        return {
            monthlyPayment: monthlyPayment.toFixed(2),
            totalPayment: (monthlyPayment * payments).toFixed(2),
            totalInterest: (monthlyPayment * payments - totalAmount).toFixed(2),
            totalWithDownpayment: (
                monthlyPayment * payments -
                downPayment
            ).toFixed(2)
        }
    }
    return null
}

export default ({ data, title }) => {
    const [totalAmount, setTotalAmount] = useState(0)
    const [downPayment, setDownPayment] = useState(0)
    const [interestRate, setInterestRate] = useState(0)
    const [amortizationPeriod, setAmortizationPeriod] = useState(0)
    const [showResults, setShowResults] = useState(false)

    const result = calculate({
        totalAmount,
        downPayment,
        interestRate,
        amortizationPeriod
    })

    const hasError =
        result == null ||
        Object.keys(result).reduce((acc, key) => {
            return acc || !isFinite(result[key]) || result[key] < 0
        }, false)

    return (
        <div className={styles.mortgageCalculatorForm}>
            <div className="wrapper">
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <Form
                            className={styles.card}
                            requiredFields={['name', 'mobile', 'email']}
                        >
                            <h1 className={styles.title}>{title}</h1>
                            <ul className={styles.insuranceDreams}>
                                <li>
                                    Protect your dream home and have a peace of
                                    mind with less than 2Dhs\DAY
                                </li>
                                <li>
                                    Ensure Your Home contents with less than
                                    1Dhs\DAY
                                </li>
                            </ul>
                            <div className={styles.body}>
                                <Input
                                    label="Name"
                                    placeholder="Your name"
                                    required
                                />
                                <PhoneInput
                                    label="Mobile"
                                    placeholder="Your mobile"
                                    required
                                />
                                <Input
                                    label="Email"
                                    placeholder="Your email"
                                    required
                                />
                                <div className={styles.options}>
                                    <Input
                                        label="Get more information"
                                        type="checkbox"
                                    />
                                </div>

                                <Button className={styles.submit}>
                                    Submit
                                </Button>
                                <p className={styles.insuranceText}>
                                    All insurance offered is through R2S
                                    Insurance Brokers. R2S Insurance Brokers is
                                    incorporated under the United Arab Emirates
                                    laws and regulated by the Insurance
                                    Authority with Certificate number 277
                                    Insurance is the subject matter of
                                    solicitation. Visitors are hereby informed
                                    that their information submitted on the
                                    website may be shared with insurers. The
                                    information provided on this website/page is
                                    only for information sake. The products
                                    information for comparison displayed on this
                                    website are of the insurers with whom the
                                    broker has an agreement with Product
                                    information is authentic and solely based on
                                    the information received from the Insurer
                                    and provided to Propertyeportal.com by the
                                    broker.
                                </p>
                            </div>
                        </Form>
                        <h2>Mortgage Calculator</h2>
                        <div className={styles.form} id="form">
                            <Input
                                type="number"
                                label="Total amount (AED)"
                                onChange={totalAmount =>
                                    setTotalAmount(totalAmount)
                                }
                            />
                            <Input
                                type="text"
                                label="Down payment (AED)"
                                onChange={downPayment =>
                                    setDownPayment(downPayment)
                                }
                            />
                            <Input
                                type="number"
                                label="Interest Rate (%)"
                                onChange={interestRate =>
                                    setInterestRate(interestRate)
                                }
                            />
                            <Input
                                type="text"
                                label="Amortization Period (years)"
                                onChange={amortizationPeriod =>
                                    setAmortizationPeriod(amortizationPeriod)
                                }
                            />
                            <div className={styles.row}>
                                <Button
                                    className={styles.marginTopPlus}
                                    onClick={() => setShowResults(!showResults)}
                                >
                                    Calculate now
                                </Button>
                                {hasError && showResults ? (
                                    <div className={styles.error}>
                                        Please enter valid data then click on{' '}
                                        <span>Calculate now</span>
                                    </div>
                                ) : null}
                            </div>
                            {result && showResults && !hasError ? (
                                <div className={styles.resultContainer}>
                                    <h3>
                                        For a mortgage of AED
                                        <span>{totalAmount}</span>
                                        amortized over
                                        <span>{amortizationPeriod}</span>
                                        years, your Monthly payment is:
                                    </h3>
                                    <div className={styles.result}>
                                        Mortgage Payment:
                                        <span>{result.monthlyPayment}</span>
                                    </div>
                                    <div className={styles.result}>
                                        Total Mortgage with Interest:
                                        <span>{result.totalPayment}</span>
                                    </div>
                                    <div className={styles.result}>
                                        Total with down payment:
                                        <span>
                                            {result.totalWithDownpayment}
                                        </span>
                                    </div>
                                    <div className={styles.result}>
                                        Total interest:
                                        <span>{result.totalInterest}</span>
                                    </div>
                                </div>
                            ) : null}
                            <RelatedPosts data={data} />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                        <CalculatorImage className={styles.art} />
                    </div>
                </div>
            </div>
        </div>
    )
}
