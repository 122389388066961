import React from 'react'

import SEO from '../components/seo'

import { graphql } from 'gatsby'

import Layout from '../components/layout'

import MortgageCalculatorForm from '../components/mortgage-calculator-form'

export default ({ data }) => {
    let page = data.allWordpressPage.edges[0].node

    return (
        <Layout
            breadcrumb={[
                {
                    title: 'Home',
                    link: '/'
                },
                {
                    title: 'Property Insurance'
                },
                {
                    title: 'Mortgage Calculator'
                }
            ]}
            marketTicker
        >
            <SEO
                title={page.yoast_meta.yoast_wpseo_title}
                description={page.yoast_meta.yoast_wpseo_metadesc}
            />
            <MortgageCalculatorForm
                data={data}
                title={page.yoast_meta.yoast_wpseo_title}
            />
        </Layout>
    )
}

export const query = graphql`
    {
        relatedPosts: allWordpressPost(
            filter: { categories: { elemMatch: { slug: { eq: "mortgage" } } } }
            limit: 10
        ) {
            edges {
                node {
                    title
                    slug
                }
            }
        }
        allWordpressPage(filter: { slug: { eq: "mortgage-calculator" } }) {
            edges {
                node {
                    yoast_meta {
                        yoast_wpseo_title
                        yoast_wpseo_metadesc
                    }
                }
            }
        }
    }
`
